<template>
  <transition name="fade">
    <div class="weetUploadContainer" v-show="file">
      <PopUpTitle v-if="!withoutTitle" :title="$t('createWeetComponent.upload.title')" class="title"/>
      <div v-if="optionChoosed">
        <CenterCenter v-if="file">
          <div>
            <div class="progressSubtitle">{{ $t('createWeetComponent.upload.subtitle', { file: file.name }) }}</div>
            <div class="pourcentageValue">{{ progressValue.toFixed(2) }}%</div>
            <b-progress size="is-small" :value="progressValue" type="is-primary" class="progressBar">
            </b-progress>
          </div>
        </CenterCenter>
        <div class="optionUploadCancel" :class="{modeIntegration:modeIntegration}">
          <w-button @click="cancelUpload" color="outlined" :enabled="canICancel">{{
              $t('createWeetComponent.upload.option.cancel')
            }}
          </w-button>
        </div>
      </div>
      <div v-else-if="withNoiseCancelling">
        <!-- Option Panel -->
        <div class="optionUploadLine" :class="{modeIntegration:modeIntegration}"
        v-if="file">
          <div class="fileNameLine">
            <div class="iconFile"><w-icon icon="file-video-outline" size="medium" /> </div>
            <div class="labelFile">
              <div class="filenameAdvice">
                {{ $t('createWeetComponent.upload.option.filename') }}
              </div>
              {{file.name}}
            </div>
          </div>
          <div class="noiseCancelationLine">
            <div class="optionUploadLabelNoiseCancelling">
              {{ $t('createWeetComponent.upload.option.noiseCancelationLvl') }}
            </div>
            <div class="optionUploadItem">
              <w-switch :value="modeIntegration" size="small"  @input="selectNoiseConcelation" />
            </div>
          </div>
        </div>
        <div class="optionUploadValidate" v-if="!buttonNext">
          <w-button @click="validateOptionUpload">{{ $t('createWeetComponent.upload.option.validate') }}</w-button>
        </div>
        <div class="optionUploadNext" v-else>
          <w-button @click="validateOptionUpload">{{ $t('createWeetComponent.upload.option.next') }}</w-button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import store from '@/store';
import {CREATE_MY_WEET} from '@/store/myWeet/myWeetAction';
import {
  CLEAR_STICKER_MEDIAID,
  GENERATE_MAIN_MEDIAID,
} from "@/store/recordingState/recordStateAction";
import {ChunkMedia} from "@/store/media/mediaModel";
import {sendMessageToExtention} from "@/utils/extentionUtil";
import {SourceVideoType} from "@/enum/SourceVideoEnum";
import {UPLOAD_FILE_DESCRIPTION} from "@/store/upload/uploadAction";
import PopUpTitle from "@/components/title/PopUpTitle.vue";
import CenterCenter from "@/components/layout/CenterCenter.vue";
import {UploadMedia} from "@/store/upload/uploadMediaModel";
import {CREATE_CHUNK_MEDIA, METADATA_MEDIA, REMOVE_MEDIA_FROM_QUEUE} from "@/store/media/mediaAction";
import delay from "delay";
import {getUploadMediaChunkToSync} from "@/utils/weetUtil";
import { SAVE_TIMELINE_IF_NEED, TIMELINE_UNSAVED} from "@/store/timeLine/timeLineAction";
import {alertMessage} from "@/utils/dialog";
import WSwitch from "@/components/wrapper/w-switch.vue";
import WButton from "@/components/wrapper/w-button.vue";
import WHelp from "@/components/wrapper/w-help.vue";
import WToolTip from "@/components/wrapper/w-toolTip.vue";
import WSelect from "@/components/wrapper/w-select.vue";
import WIcon from "@/components/wrapper/w-icon.vue";
import {initWeetEditing} from "@/utils/createWeetUtil";


@Component({
  components: {WIcon, WSelect, WToolTip, WHelp, WButton, WSwitch, CenterCenter, PopUpTitle},
})
export default class CreateWeetUploadStep extends Vue {

  @Prop({default: null})
  private file!: File;

  @Prop({default:false})
  private withoutTitle!:boolean;

  @Prop({default:false})
  private withNoiseCancelling!:boolean;
  @Prop({default:false})
  private buttonNext!:boolean;
  @Prop({default:false})
  private modeIntegration!:boolean;

  private noiseCancelationLvl = 0;
  private canICancel: boolean = true;
  private numberOfChunk: number = 0;

  private progressValue: number = 0;

  private optionChoosed: boolean = false;
  private canceled:boolean=false;


  private async mounted(){
    await delay(1000);
    if(this.modeIntegration){
      this.noiseCancelationLvl=1;
    }else{
      this.noiseCancelationLvl=0;
    }
  }
  private async computeProgressValue(): Promise<void> {

    // total pourcentage
    const total = this.numberOfChunk * 100;
    let current = total;
    current -= getUploadMediaChunkToSync(store.getters.getMainMediaId) * 100;
    var uploadMediasInProgress = store.getters.getUploadMedia(store.getters.getMainMediaId);
    for (const uploadMediaInProgress of uploadMediasInProgress as UploadMedia[]) {
      current += uploadMediaInProgress.pourcent;
    }
    this.progressValue = current / total * 100;
    if (this.progressValue < 100) {

      await delay(500);
      this.computeProgressValue();
    } else {
      this.canICancel = false;
      let value: any = {};
      for (let i = 0; i < 10; i++) {
        await delay(2000);
        if(this.canceled){
          return
        }
        // store time before added metadata (avoid other change on getTimerRecord)
        var timeMax = this.timeMaxAvailableMillisecond;
        value = await store.dispatch(METADATA_MEDIA, store.getters.getMainMediaId);
        if (value.duration > 0) {
          if (value.duration * 1000 > timeMax) {
            alertMessage(this.$t('createWeetComponent.upload.unfitPopUp.message').toString(), this.$t('createWeetComponent.upload.unfitPopUp.title').toString());
          }
          // now we have the duration so we inform that the timeline need to be saved
          await store.dispatch(TIMELINE_UNSAVED);
          // Try to save timeLine if possible
          await store.dispatch(SAVE_TIMELINE_IF_NEED);
          break;
        }
      }

      this.$emit("close",true);
    }
  }

  private selectNoiseConcelation(value){
    if(value){
      this.noiseCancelationLvl=1;
    }else{
      this.noiseCancelationLvl=0;
    }
  }

  get timeMaxAvailableMillisecond(): number {
    return store.getters.getWeetMaxTime * 1000 - (store.getters.getTimerRecord);
  }

  private validateOptionUpload() {
    this.$emit('noisecancelationSelected')
    this.optionChoosed = true;
  }

  private reinit() {
    this.numberOfChunk = 0;
    this.progressValue = 0;
    this.canICancel = true;
    this.optionChoosed = !this.withNoiseCancelling;
  }

  @Watch("file")
  private async onNewFile(): Promise<void> {
    if (this.file) {
      this.reinit();

      await this.initWeetEditing(); // obtain a weet ID
      await store.dispatch(CLEAR_STICKER_MEDIAID);
      var mediaID = await store.dispatch(GENERATE_MAIN_MEDIAID);
      await store.dispatch(UPLOAD_FILE_DESCRIPTION, this.file)
      var step = (1024 * 1024) * 5;
      var total = this.file.size;
      this.numberOfChunk = 0;
      var partNumber = 0;
      for (let pointer = 0; pointer < total; pointer += step) {
        const blob = this.file.slice(pointer, pointer + step);
        partNumber++;
        this.numberOfChunk++;
        const chunk = new ChunkMedia();
        chunk.blob = blob;
        chunk.type = SourceVideoType.UPLOAD;
        chunk.mediaID = mediaID;
        chunk.partNumber = partNumber;
        chunk.weetID = store.getters.getEditingWeetID;


        if (pointer + step >= total) {
          chunk.lastPart = true;
          // for the last part we wait the user for choosed option
          while (!this.optionChoosed) {
            await delay(200);
          }
          chunk.noiseCancelation = this.noiseCancelationLvl;
          chunk.transcoding = true;
        }

        chunk.maxTime = this.timeMaxAvailableMillisecond;

        store.dispatch(CREATE_CHUNK_MEDIA, chunk);
      }
      await delay(500);
      this.computeProgressValue();
    }
  }

  private async initWeetEditing() {
    if (store.getters.getEditingWeetID === '') {
      initWeetEditing()
    }
  }


  private cancelUpload() {
    store.dispatch(REMOVE_MEDIA_FROM_QUEUE, store.getters.getMainMediaId);
    this.$emit('close',false);
  }


}

</script>

<style scoped lang="scss">

.weetUploadContainer {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 12px;
  padding: 48px;
  text-align: left;

  .progressSubtitle {
    margin-bottom: 24px;
    font-size: 16px;
    min-width: 536px;
  }

  .optionUpload {
    margin-top: 48px;
    margin-bottom: 24px;
    font-size: 16px;
    color: var(--dark);
    min-width: 536px;
  }


  .optionUploadLine {
    width: calc(100% - 0px);
    margin: auto;
    margin-top: 150px;
    border: solid 1px var(--light1);
    border-radius: 8px;
    .fileNameLine{
      display: flex;
      align-items: center;
      padding: 16px;
      .iconFile{
        margin-right: 8px;
      }
      .labelFile{
        .filenameAdvice{
          color: var(--light2);
          font-size: 12px;
        }
      }
    }
    .noiseCancelationLine{

      border-top: solid 1px var(--light1) ;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 16px;
      .optionUploadLabelNoiseCancelling{
        flex: 1;
        font-size: 16px;
      }

    }
    &.modeIntegration{
      margin-top: 32px;
    }


  }

  .optionUploadNext {
    text-align: center;
    margin-top: 48px;
    width: 100%;
    right: 48px;
    bottom: 48px;
  }
  .optionUploadValidate {
    text-align: right;
    position: absolute;
    width: 100%;
    right: 48px;
    bottom: 48px;
  }

  .optionUploadCancel {
    text-align: right;
    position: absolute;
    width: 100%;
    right: 48px;
    bottom: 48px;
    &.modeIntegration{
      text-align: center;
      position: absolute;
      right: 0px;
      bottom: inherit;
      top:212px;
    }
  }

  .progressBar {
    width: 100%;
    margin-bottom: 112px;
  }
}
</style>
